import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const editSite = async (axiosAuth: AxiosInstance, siteData: any) => {
  const { id, ...rest } = siteData;
  const response = await axiosAuth.put(
    `${process.env.REACT_APP_API_BASE_URL}sites/${id}/`,
    rest
  );
  return response.data;
};

export function useEditSite() {
  const axiosAuth = useAxiosAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (siteData: any) => editSite(axiosAuth, siteData),
    onSuccess: () => {
      queryClient.invalidateQueries(["portfolioTable"]); 
    },
  });
}
