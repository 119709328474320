import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Function to post a new portfolio
const addNewPortfolio = async (axiosAuth: AxiosInstance, portfolioData: any) => {
  const response = await axiosAuth.post(
    `${process.env.REACT_APP_API_BASE_URL}portfolios/`, 
    portfolioData
  );
  return response.data;
};

// Custom hook to handle adding a new portfolio
export function useAddNewPortfolio() {
  const axiosAuth = useAxiosAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (portfolioData: any) => addNewPortfolio(axiosAuth, portfolioData),
    onSuccess: () => {
      queryClient.invalidateQueries(["portfoliosDropdown"]); // Invalidate portfolios data after success
    },
  });
}
