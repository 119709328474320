import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SnackbarContent } from "@mui/material";

const NotificationSnack = ({ isOpen, message, handleClose, isValid }: any) => {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <HighlightOffIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const snackColor = isValid ? "#d4e4e4" : "#B2160E";
  const textColor = isValid ? "#000" : "#fff";

  return (
    <Snackbar
      sx={{ zIndex: "4 !important" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={5000}
      open={isOpen}
      onClose={handleClose}
    >
      <SnackbarContent
        message={message}
        action={action}
        sx={{
          backgroundColor: snackColor,
          color: textColor,
        }}
      />
    </Snackbar>
  );
};

export default NotificationSnack;
