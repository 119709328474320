import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery } from "@tanstack/react-query";

const transformSiteGeometryData = (response: any) => {
  return response.data;
};

const extractIdFromUrl = (url: string): any => {
  return url.match(/\/site_geometry\/(\d+)\//)?.[1];
};

const fetchSiteGeometry = async (axiosAuth: AxiosInstance, id: string) => {
  const idFromUrl = extractIdFromUrl(id);
  const response = await axiosAuth.get(
    `${process.env.REACT_APP_API_BASE_URL}site_geometry/${idFromUrl}/`
  );
  return response;
};

export function useSiteGeometry(session: any, id: string) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["site_geometry"],
    queryFn: async () => await fetchSiteGeometry(axiosAuth, id),
    select: (data) => transformSiteGeometryData(data),
    enabled: !!session?.user && !!id,
    refetchOnWindowFocus: false,
  });
}
