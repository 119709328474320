import React, { useContext, useState, type ReactNode, useEffect } from "react";
import NotificationSnack from "../components/NotificationSnack/NotificationSnack.tsx";

const NotificationContext = React.createContext<any | null>(null);

export const NotificationProvider: React.FC<{
  children: ReactNode[] | ReactNode;
}> = ({ children }) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [notiOpen, setNotiOpen] = useState(false);
  const [notiValidation, setNotiValidation] = useState<boolean | undefined>(
    undefined
  );
  const [notiMsg, setNotiMsg] = useState("");

  useEffect(() => {
    // Load notifications from localStorage on component mount
    const savedNotifications = JSON.parse(
      localStorage.getItem("notifications") || "[]"
    );
    setNotifications(savedNotifications);
  }, []);

  useEffect(() => {
    // Save notifications to localStorage whenever they change
    localStorage.setItem("notifications", JSON.stringify(notifications));
  }, [notifications]);

  const handleClose = () => {
    setNotiOpen(false);
  };

  const addNotification = (msg: string, isValid: boolean = false) => {
    setNotiOpen(true);
    setNotiValidation(isValid);
    setNotiMsg(msg);
  };

  const value = {
    notifications,
    notificationCount: notifications.length,
    addNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      <NotificationSnack
        isOpen={notiOpen}
        message={notiMsg}
        isValid={notiValidation}
        handleClose={handleClose}
      />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationsProvider"
    );
  }
  return context;
};
