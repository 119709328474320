import { Box, CircularProgress } from "@mui/material";
import React from "react";

const LoadingStatus = () => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="30vh"
        width="100%"
      >
        <CircularProgress color="success" />
      </Box>
    </>
  );
};

export default LoadingStatus;
