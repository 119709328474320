import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery, useMutation } from "@tanstack/react-query";

const transformFullReport = (response: any) => {
  return response.data;
};

const fetchFullReport = async (axiosAuth: AxiosInstance, filters: any) => {
  let url = new URL(`${process.env.REACT_APP_API_BASE_URL}sites/full_report/`);
  Object.entries(filters).forEach(([filterKey, value]: any) => {
    const stringValue = value.toString();
    if (value.length > 0) {
      url.searchParams.append(filterKey, stringValue);
    }
  });
  let response: any = {
    one_mile_children: [],
    one_mile_parents: [],
    one_mile_residential: [],
    ten_mile_children: [],
    ten_mile_parents: [],
    ten_mile_residential: [],
  };
  try {
    response = await axiosAuth.get(url.href);
  } catch (e) {
    console.log("error");
  }
  return response;
};

export function useFullReport(session: any, filters: any) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["fullReport", filters],
    queryFn: async () => await fetchFullReport(axiosAuth, filters),
    select: (data) => transformFullReport(data),
    enabled: !!session?.user,
    refetchOnWindowFocus: false,
  });
}

export function usePostReport() {
  const axiosAuth = useAxiosAuth();
  return useMutation({
    mutationFn: async (parameters: any) => {
      let url = new URL(
        `${process.env.REACT_APP_API_BASE_URL}sites/full_report/`
      );
      let payload: any = {};
      Object.entries(parameters).forEach(([filterKey, value]: any) => {
        const stringValue = value.toString();
        if (value.length > 0) {
          payload[filterKey] = stringValue;
        }
      });
      return await axiosAuth.post(url.href, payload, {
        responseType: "blob",
      });
    },
  });
}
