import React, { useEffect, useState } from "react";

const HealthCheck: React.FC = () => {
  const [status, setStatus] = useState<string>("Checking...");

  const checkHealth = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}health-check/`
      );
      if (response.ok) {
        const data = await response.json();
        setStatus(data.status);
      } else {
        setStatus("ERROR");
      }
    } catch (error) {
      setStatus("ERROR");
    }
  };

  useEffect(() => {
    checkHealth();
  }, []);

  return (
    <div>
      <h2>Health Check</h2>
      <p>Status: {status}</p>
    </div>
  );
};

export default HealthCheck;
