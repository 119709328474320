import { useEffect } from "react";
import { axiosAuth } from "../axios.ts";
import { useAuth } from "../../context/auth.context.tsx";

const useAxiosAuth = () => {
  const { session, logOutAction } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosAuth.interceptors.request.use((config) => {
      if (!config.headers.Authorization) {
        config.headers.Authorization = `Token ${session?.token}`;
      }
      return config;
    });

    const responseIntercept = axiosAuth.interceptors.response.use(
      (response) => response,
      async (error) => {
        const statusCode = error.response.status;
        if (statusCode === 401 || statusCode === 403) {
          await logOutAction();
        }
        return await Promise.reject(error);
      }
    );
    return () => {
      axiosAuth.interceptors.request.eject(requestIntercept);
      axiosAuth.interceptors.response.eject(responseIntercept);
    };
  }, [session]);

  return axiosAuth;
};

export default useAxiosAuth;
