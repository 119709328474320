export const BasicSitesTableColumns: any[] = [
  {
    accessorKey: "Parent_SAM ID",
    header: "Parent SAM ID",
    filterVariant: "text",
    filterFn: "contains",
    enableResizing: true,
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },
  {
    accessorKey: "Parent_Site Name",
    header: "Parent Site Name",
    filterVariant: "text",
    filterFn: "contains",
    enableResizing: true,
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow.Parent_City ? originalRow.Parent_City : "Unknown",
    accessorKey: "Parent_City",
    enableResizing: true,
    header: "Parent City",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow.Parent_State ? originalRow.Parent_State : "Unknown",
    accessorKey: "Parent_State",
    enableResizing: true,
    header: "Parent State",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },

  {
    accessorFn: (originalRow: any) =>
      originalRow["Docket ID"] ? originalRow["Docket ID"].toString() : "",
    accessorKey: "Docket ID",
    header: "Docket ID",
    filterVariant: "text",
    filterFn: "equals",
    enableResizing: true,
    Cell: ({ row }: any) =>
      row.original["Docket ID"] ? row.original["Docket ID"].toString() : "",
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },
  {
    accessorFn: (originalRow: any) => originalRow.Parent_Latitude,
    accessorKey: "Parent_Latitude",
    header: "Parent Latitude",
    filterVariant: "number",
    Cell: ({ row }: any) => row.original.Parent_Latitude,
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) => originalRow.Parent_Longitude,
    accessorKey: "Parent_Longitude",
    header: "Parent Longitude",
    filterVariant: "number",
    Cell: ({ row }: any) => row.original.Parent_Longitude,
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow.Parent_Source ? originalRow.Parent_Source : "Unknown",
    accessorKey: "Parent_Source",
    enableResizing: true,
    header: "Parent Source",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },

  {
    accessorFn: (originalRow: any) => originalRow["Aggregate DC Capacity"],
    accessorKey: "Aggregate DC Capacity",
    header: "Aggregate DC Capacity",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Aggregate DC Capacity"].toLocaleString("en-US"),
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) => originalRow["Aggregate AC Capacity"],
    accessorKey: "Aggregate AC Capacity",
    header: "Aggregate AC Capacity",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Aggregate AC Capacity"].toLocaleString("en-US"),
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
];

export const AggregateSitesTableColumns: any[] = [
  {
    accessorKey: "Parent_SAM ID",
    header: "Parent SAM ID",
    filterVariant: "text",
    filterFn: "contains",
    enableResizing: true,
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },
  {
    accessorKey: "Parent_Site Name",
    header: "Parent Site Name",
    filterVariant: "text",
    filterFn: "contains",
    enableResizing: true,
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow.Parent_City ? originalRow.Parent_City : "Unknown",
    accessorKey: "Parent_City",
    enableResizing: true,
    header: "Parent City",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow.Parent_State ? originalRow.Parent_State : "Unknown",
    accessorKey: "Parent_State",
    enableResizing: true,
    header: "Parent State",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow.Parent_Source ? originalRow.Parent_Source : "Unknown",
    accessorKey: "Parent_Source",
    enableResizing: true,
    header: "Parent Source",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },
  {
    accessorFn: (originalRow: any) => originalRow.Parent_Latitude,
    accessorKey: "Parent_Latitude",
    header: "Parent Latitude",
    filterVariant: "number",
    Cell: ({ row }: any) => row.original.Parent_Latitude,
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) => originalRow.Parent_Longitude,
    accessorKey: "Parent_Longitude",
    header: "Parent Longitude",
    filterVariant: "number",
    Cell: ({ row }: any) => row.original.Parent_Longitude,
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },

  {
    accessorKey: "Child_SAM ID",
    header: "Child SAM ID",
    filterVariant: "text",
    filterFn: "contains",
    enableResizing: true,
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },

  {
    accessorKey: "Child_Site Name",
    header: "Child Site Name",
    filterVariant: "text",
    filterFn: "contains",
    enableResizing: true,
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },

  {
    accessorFn: (originalRow: any) =>
      originalRow.Child_City ? originalRow.Child_City : "Unknown",
    accessorKey: "Child_City",
    enableResizing: true,
    header: "Child City",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },

  {
    accessorFn: (originalRow: any) =>
      originalRow.Child_State ? originalRow.Child_State : "Unknown",
    accessorKey: "Child_State",
    enableResizing: true,
    header: "Child State",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },

  {
    accessorFn: (originalRow: any) =>
      originalRow.Child_Source ? originalRow.Child_Source : "Unknown",
    accessorKey: "Child_Source",
    enableResizing: true,
    header: "Child Source",
    filterVariant: "select",
    enableColumnFilterModes: false,
  },

  {
    accessorFn: (originalRow: any) => originalRow.Child_Latitude,
    accessorKey: "Child_Latitude",
    header: "Child Latitude",
    filterVariant: "number",
    Cell: ({ row }: any) => row.original.Child_Latitude,
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) => originalRow.Child_Longitude,
    accessorKey: "Child_Longitude",
    header: "Child Longitude",
    filterVariant: "number",
    Cell: ({ row }: any) => row.original.Child_Longitude,
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },

  {
    accessorFn: (originalRow: any) => originalRow["Child_DC Capacity (kW)"],
    accessorKey: "Child_DC Capacity (kW)",
    header: "Child DC Capacity (kW)",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Child_DC Capacity (kW)"].toLocaleString("en-US"),
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) => originalRow["Child_AC Capacity (kW)"],
    accessorKey: "Child_AC Capacity (kW)",
    header: "Child AC Capacity (kW)",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Child_AC Capacity (kW)"].toLocaleString("en-US"),
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },

  {
    accessorFn: (originalRow: any) =>
      originalRow["Parent_Aggregate DC Capacity"],
    accessorKey: "Parent_Aggregate DC Capacity",
    header: "Parent Aggregate DC Capacity",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Parent_Aggregate DC Capacity"].toLocaleString("en-US"),
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow["Parent_Aggregate AC Capacity"],
    accessorKey: "Parent_Aggregate AC Capacity",
    header: "Parent Aggregate AC Capacity",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Parent_Aggregate AC Capacity"].toLocaleString("en-US"),
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow["Child_Docket ID"]
        ? originalRow["Child_Docket ID"].toString()
        : "",
    accessorKey: "Child_Docket ID",
    header: "Child Docket ID",
    filterVariant: "text",
    filterFn: "equals",
    enableResizing: true,
    Cell: ({ row }: any) =>
      row.original["Child_Docket ID"]
        ? row.original["Child_Docket ID"].toString()
        : "",
    columnFilterModeOptions: [
      "contains",
      "startsWith",
      "endsWith",
      "equals",
      "notEquals",
    ],
  },
  {
    accessorFn: (originalRow: any) => originalRow["Distance (miles)"],
    accessorKey: "Distance (miles)",
    header: "Distance (miles)",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Distance (miles)"].toLocaleString("en-US"),
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow["Child_DC Source"] ? originalRow["Child_DC Source"] : 0,
    accessorKey: "Child_DC Source",
    header: "Child DC Source",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Child_DC Source"]
        ? row.original["Child_DC Source"].toLocaleString("en-US")
        : "0",
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
  {
    accessorFn: (originalRow: any) =>
      originalRow["Child_AC Source"] ? originalRow["Child_AC Source"] : 0,
    accessorKey: "Child_AC Source",
    header: "Child AC Source",
    filterVariant: "number",
    Cell: ({ row }: any) =>
      row.original["Child_AC Source"]
        ? row.original["Child_AC Source"].toLocaleString("en-US")
        : "0",
    enableResizing: true,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const a: any = rowA.getValue(columnId);
      const b: any = rowB.getValue(columnId);
      return a - b;
    },
  },
];

export const initialStates: any = {
  density: "compact",
  showColumnFilters: true,
};

export const TablesDistribution = [
  {
    title: "Sites within 1 mile",
    columns: BasicSitesTableColumns,
    ref: "one_mile_parents",
  },
  {
    title: "Sites between 1 and 10 miles",
    columns: BasicSitesTableColumns,
    ref: "ten_mile_parents",
  },
  {
    title: "Aggregate of Sites within 1 mile",
    columns: AggregateSitesTableColumns,
    ref: "one_mile_children",
  },
  {
    title: "Aggregate of Sites between 0 and 10 miles",
    columns: AggregateSitesTableColumns,
    ref: "ten_mile_children",
  },
];
