import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const addNewSite = async (axiosAuth: AxiosInstance, siteData: any) => {
  const response = await axiosAuth.post(`${process.env.REACT_APP_API_BASE_URL}sites/`, siteData);
  return response.data; 
};

const runSetPointNeighbors = async (axiosAuth: AxiosInstance, siteUrl: string) => {
  const siteId = siteUrl.split('/').filter(Boolean).pop(); 
  
  if (siteId) {
    const response = await axiosAuth.get(`${process.env.REACT_APP_API_BASE_URL}sites/${siteId}/set_point_neighbors/`);
    return response.data; 
  } else {
    throw new Error("Invalid site URL");
  }
};


export function useAddNewSite() {
  const axiosAuth = useAxiosAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (siteData: any) => {
      const site = await addNewSite(axiosAuth, siteData);
      await runSetPointNeighbors(axiosAuth, site.url); 
      return site; 
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["portfolioTable"]); 
    },
  });
}