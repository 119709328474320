import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { useAuth } from "../../context/auth.context.tsx";

const Login: React.FC = () => {
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });

  const { logInAction } = useAuth();

  const onchangeForm = (key, value) => {
    setLoginForm({ ...loginForm, [key]: value });
  };

  const handleLogin = () => {
    if (loginForm.username !== "" && loginForm.password !== "") {
      logInAction(loginForm);
      return;
    }
    alert("Please provide a valid input");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#292929"
      onKeyDown={handleKeyDown}
    >
      <Logo style={{ marginBottom: 20, width: "250px", height: "250px" }} />
      <Typography
        variant="h5"
        width={"500px"}
        textAlign={"center"}
        style={{ color: "white", marginBottom: 20, fontWeight: "bold" }}
      >
        Enter your email and password to login into Qualifying Facility-Distance
        Analysis
      </Typography>
      <TextField
        label="Username"
        title="Username"
        variant="outlined"
        autoComplete="off"
        value={loginForm.username}
        onChange={(e) => onchangeForm("username", e.target.value)}
        margin="normal"
        focused
        sx={{
          backgroundColor: "#00000040",
          borderRadius: "5px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#FFFFFF", // Border color
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF", // Border color when focused
            },
            "& input": {
              color: "#FFFFFF", // Input text color
            },
          },
          "& label": {
            color: "#FFFFFF", // Label color
          },
          "& label.Mui-focused": {
            color: "#FFFFFF", // Label color when focused
          },
        }}
      />
      <TextField
        label="Password"
        title="Password"
        type="password"
        variant="outlined"
        autoComplete="off"
        value={loginForm.password}
        margin="normal"
        focused
        onChange={(e) => onchangeForm("password", e.target.value)}
        sx={{
          backgroundColor: "#00000040",
          borderRadius: "5px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#FFFFFF", // Border color
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF", // Border color when focused
            },
            "& input": {
              color: "#FFFFFF", // Input text color
            },
          },
          "& label": {
            color: "#FFFFFF", // Label color
          },
          "& label.Mui-focused": {
            color: "#FFFFFF", // Label color when focused
          },
        }}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#2E7C67",
          color: "#FFFFFF",
          marginTop: "10px",
          "&:hover": {
            backgroundColor: "#4EBA74",
            color: "#FFFFFF",
          },
          "&:active": {
            backgroundColor: "#000000",
          },
        }}
        onClick={handleLogin}
      >
        Log In
      </Button>
    </Box>
  );
};

export default Login;
