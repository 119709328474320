// deleteSite.ts

import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const deleteSite = async (axiosAuth: AxiosInstance, id: string) => {
  const response = await axiosAuth.delete(`${process.env.REACT_APP_API_BASE_URL}sites/${id}/`);
  return response.data;
};

export function useDeleteSite() {
  const axiosAuth = useAxiosAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteSite(axiosAuth, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["portfolioTable"]); 
    },
  });
}
