import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery } from "@tanstack/react-query";

// GET ALL SITES

const transformSites = (response: any) => {
  return response.data;
};

const fetchSites = async (axiosAuth: AxiosInstance) => {
  const response = await axiosAuth.get(
    `${process.env.REACT_APP_API_BASE_URL}sites/ui_list/`
  );
  return response;
};

export function useSites(session: any) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["sites"],
    queryFn: async () => await fetchSites(axiosAuth),
    select: (data) => transformSites(data),
    enabled: !!session?.user,
    refetchOnWindowFocus: false,
  });
}

// GET SINGLE SITE

const transformSiteData = (response: any) => {
  const data = [response.data].map((item: any) => ({
    ...item,
    portfolio: item.portfolio
      ? parseInt(item.portfolio.match(/\/portfolios\/(\d+)\//)?.[1])
      : 0,
    dc_source: item.dc_source ? item.dc_source.replace(/_/g, " ") : "",
    ac_source: item.ac_source ? item.ac_source.replace(/_/g, " ") : "",
  }));
  return data[0];
};

const fetchSite = async (axiosAuth: AxiosInstance, id: number) => {
  const response = await axiosAuth.get(
    `${process.env.REACT_APP_API_BASE_URL}sites/${id}/`
  );
  return response;
};

export function useSite(session: any, id: number) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["site"],
    queryFn: async () => await fetchSite(axiosAuth, id),
    select: (data) => transformSiteData(data),
    enabled: !!session?.user,
    refetchOnWindowFocus: false,
  });
}
