import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery } from "@tanstack/react-query";
import { dynamicSortObjt } from "../UtilFunctions.ts";

const transformPortfolios = (response: any) => {
  const data = response.data;

  const result = data.map((element: any) => {
    return {
      id: element.id,
      name: element.name.replace(/_/g, " "),
    };
  });

  result.sort(dynamicSortObjt("name"));

  return result;
};

const fetchPortfolios = async (axiosAuth: AxiosInstance) => {
  const response = await axiosAuth.get(
    `${process.env.REACT_APP_API_BASE_URL}portfolios/ui_list/`
  );
  return response;
};

export function usePortfolios(session: any) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["portfolios"],
    queryFn: async () => await fetchPortfolios(axiosAuth),
    select: (data) => transformPortfolios(data),
    enabled: !!session?.user,
    refetchOnWindowFocus: false,
  });
}
