import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { CSVLink } from "react-csv";
import { useState } from "react";
import { useNotification } from "../../../context/notification.context.tsx";
import LoadingStatus from "../../LoadingStatus/LoadingStatus.tsx";

export default function DownloadFileModal({
  fileName,
  open,
  onClose,
  Data,
  isFile = false,
  isLoading = false,
}: any) {
  const { addNotification } = useNotification();
  const [name, setName] = useState(fileName);
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    // Validate input
    if (e.target.value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const downloadFile = () => {
    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([Data]));

    // Create a link element and trigger the download
    const link: any = document.createElement("a");
    link.href = url;

    // Rename the file
    link.setAttribute(
      "download",
      `${name.replace(/ /g, "_")}_${new Date().toJSON().slice(0, 10)}.xlsx`
    );

    document.body.appendChild(link);

    link.click();

    // Remove the link after the download
    link.parentNode.removeChild(link);

    onClose();
  };

  const handleSubmit = (event: any) => {
    event.stopPropagation();

    addNotification(
      `Your download “${name}” has been successfully saved.`,
      true
    );

    onClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open || isLoading}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open || isLoading}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#FFFFFF",
              width: "606px",
              height: "360px",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
              onClick={onClose}
            >
              <HighlightOffOutlinedIcon />
            </IconButton>

            {isLoading ? (
              <LoadingStatus />
            ) : (
              <>
                <Typography
                  variant="h5"
                  textAlign={"center"}
                  sx={{ marginTop: 3, marginBottom: 2, color: "#292929" }}
                >
                  Download File
                </Typography>

                <Box
                  width={"258px"}
                  height={"64px"}
                  p={2}
                  m={"auto"}
                  sx={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "5px",
                  }}
                >
                  <Typography textAlign={"center"}>Format</Typography>
                  <Typography textAlign={"center"}>
                    Excel Spreadsheet (Excel 2007 or later)
                  </Typography>
                </Box>

                <Box
                  p={3}
                  m={"auto"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="input-with-icon-adornment">
                      File Name
                    </InputLabel>
                    <Input
                      fullWidth
                      value={name}
                      onChange={handleChange}
                      autoComplete="off"
                      error={error}
                      id="input-with-icon-adornment"
                      endAdornment={
                        <InputAdornment position="end">
                          <EditOutlinedIcon />
                        </InputAdornment>
                      }
                    />
                    {error && (
                      <FormHelperText error>
                        Please enter a file name.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>

                <Box
                  p={3}
                  m={"auto"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "#2E7C67",
                      color: "#2E7C67",
                      textTransform: "none",
                      mr: 2,
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  {!isFile ? (
                    <CSVLink
                      filename={`${name.replace(/ /g, "_")}_${new Date()
                        .toJSON()
                        .slice(0, 10)}.csv`}
                      data={Data}
                      onClick={(event: any) => {
                        handleSubmit(event);
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={error}
                        sx={{
                          backgroundColor: "#2E7C67",
                          color: "white",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#4EBA74",
                            color: "#FFFFFF",
                          },
                          "&:active": {
                            backgroundColor: "#000000",
                          },
                        }}
                      >
                        Download
                      </Button>
                    </CSVLink>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={downloadFile}
                      disabled={error}
                      sx={{
                        backgroundColor: "#2E7C67",
                        color: "white",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#4EBA74",
                          color: "#FFFFFF",
                        },
                        "&:active": {
                          backgroundColor: "#000000",
                        },
                      }}
                    >
                      Download
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
