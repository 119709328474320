import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Paper,
} from "@mui/material";
import { useAddNewSite } from "../../utils/api/addNewSite.ts";
import { usePortfolios } from "../../utils/api/usePortfolios.ts";
import { useAuth } from "../../context/auth.context.tsx";
import { useNotification } from "../../context/notification.context.tsx";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

interface AddNewSiteModalProps {
  open: boolean;
  onClose: () => void;
}

const initialFormData = {
  sam_id: "",
  name: "",
  city: "",
  state: "",
  docket_id: "",
  asset_scale: "",
  address: "",
  portfolio: "",
  dc_kw: "",
  ac_kw: "",
  dc_source: "",
  ac_source: "",
  latitude: "",
  longitude: "",
};

const AddNewSiteModal: React.FC<AddNewSiteModalProps> = ({ open, onClose }) => {
  const { mutate: addSite, isLoading } = useAddNewSite();
  const { session } = useAuth();
  const { addNotification } = useNotification();
  const [formData, setFormData] = useState(initialFormData);

  const {
    data: PortfoliosData,
    isLoading: isLoadingPortfoliosData,
    isError: isErrorPortfoliosData,
  } = usePortfolios(session);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const newValue = `${process.env.REACT_APP_API_BASE_URL}portfolios/${formData.portfolio}/`;
    formData.portfolio = newValue;
    formData.dc_source = formData.dc_source.replace(/ /g, "_");
    formData.ac_source = formData.ac_source.replace(/ /g, "_");
    addSite(formData, {
      onSuccess: () => {
        addNotification("The site was added successfully", true);
        setFormData(initialFormData);
        onClose();
      },
      onError: (error) => {
        console.error("Failed to add site", error);
        addNotification("Failed to add the site", false);
      },
    });
  };

  const sourceOptions = [
    "Early stage projects",
    "Asset Registry",
    "COD Tracker",
    "SSI Constr Early Stage",
    "SSI Operational registry",
    "M&A operations",
    "utility fleet",
    "TransAlta operations",
    "ECHO Residential",
    "ASD Residential",
    "Deriva Energy",
    "X-ELIO",
    "BEP US",
  ];

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Paper
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 4,
            paddingY: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginTop: 1,
              fontWeight: "bold",
            }}
          >
            ADD A NEW SITE
          </Typography>
          <IconButton onClick={onClose}>
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Paper>

        <Box
          component="form"
          sx={{
            mt: 0,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 4,
          }}
        >
          <TextField
            label="SAM ID"
            variant="outlined"
            fullWidth
            name="sam_id"
            value={formData.sam_id}
            onChange={handleChange}
          />
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            label="City"
            variant="outlined"
            fullWidth
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
          <TextField
            label="State"
            variant="outlined"
            fullWidth
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
          <TextField
            label="Docket ID"
            variant="outlined"
            fullWidth
            name="docket_id"
            value={formData.docket_id}
            onChange={handleChange}
          />
          <TextField
            select
            label="Asset Scale"
            variant="outlined"
            fullWidth
            name="asset_scale"
            value={formData.asset_scale}
            onChange={handleChange}
          >
            <MenuItem value="RES">Residential</MenuItem>
            <MenuItem value="COM">Commercial</MenuItem>
            <MenuItem value="UTL">Utility</MenuItem>
          </TextField>
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
          <TextField
            select
            label="Portfolio"
            variant="outlined"
            fullWidth
            name="portfolio"
            value={formData.portfolio}
            onChange={handleChange}
          >
            {PortfoliosData?.map((portfolio: { id: number; name: string }) => (
              <MenuItem key={portfolio.id} value={portfolio.id}>
                {portfolio.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="DC kW"
            variant="outlined"
            fullWidth
            name="dc_kw"
            value={formData.dc_kw}
            onChange={handleChange}
          />
          <TextField
            label="AC kW"
            variant="outlined"
            fullWidth
            name="ac_kw"
            value={formData.ac_kw}
            onChange={handleChange}
          />
          <TextField
            select
            label="DC Source"
            variant="outlined"
            fullWidth
            name="dc_source"
            value={formData.dc_source}
            onChange={handleChange}
          >
            {sourceOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="AC Source"
            variant="outlined"
            fullWidth
            name="ac_source"
            value={formData.ac_source}
            onChange={handleChange}
          >
            {sourceOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Latitude"
            variant="outlined"
            fullWidth
            name="latitude"
            value={formData.latitude}
            onChange={handleChange}
          />
          <TextField
            label="Longitude"
            variant="outlined"
            fullWidth
            name="longitude"
            value={formData.longitude}
            onChange={handleChange}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              style={{
                color: "#2E7C67",
                borderColor: "#2E7C67",
                backgroundColor: "#FFFFFF",
              }}
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#2E7C67", color: "#FFFFFF" }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Create Site
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNewSiteModal;
