import { useRef, useMemo, useCallback, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_RowVirtualizer,
} from "material-react-table";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";
import DownloadFileModal from "../Modals/DownloadFile/DownloadFileModal.tsx";

const GenericTable = ({
  title,
  enableBottomToolbar = true,
  enableExport = false,
  onClickEventRow = false,
  onClickEventCell = false,
  maxWidth,
  maxHeight = "240px",
  columns: initialColumns,
  HeadersFontSize = "14px",
  multiSort = false,
  initialState = {},
  enableExpanding = false,
  rowNumbers = false,
  titleFontSize = "18px",
  tableData,
  isLoading,
  isFetching,
  isError,
  onClick,
}: any) => {
  const [openDownload, setOpenDownload] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const handleCloseDownload = () => setOpenDownload(false);
  const handleOpenDownload = () => setOpenDownload(true);

  const handleonDownload = (table: any) => {
    handleOpenDownload();
    handleonExportData(table);
  };

  const handleClearAll = useCallback((table: any) => {
    table.table.resetColumnFilters();
    table.table.resetSorting();
    table.table.resetGlobalFilter();
  }, []);

  const handleonClickCell = (cell: any) => {
    if (onClickEventCell) {
      const column_id = cell.column.id;
      if (column_id === "sam_id") {
        onClick(cell.row.original);
      }
    }
  };

  const handleonExportData = (rows_data: any) => {
    const is_cod = title.includes("COD");
    const res = rows_data.map((row: any) => {
      let data = row.original;
      if (is_cod) {
        data = {
          ...data,
          site: `https://retool.luminace.com/apps/Asset%20Intake/Asset%20Intake?System_Id=${row.original.system_id}`,
        };
      }
      return data;
    });
    setDownloadData(res);
    return res;
  };

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

  // Using useMemo to memoize columns
  const memoizedColumns = useMemo(() => initialColumns, [initialColumns]);

  const table = useMaterialReactTable({
    columns: memoizedColumns,
    data: tableData, // Use the data state directly
    defaultColumn: {
      grow: true,
    },
    globalFilterFn: "equals",
    enableExpanding,
    enableBottomToolbar,
    filterFromLeafRows: true,
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 10,
      },
    },
    muiTableBodyCellProps: ({ cell }) => ({
      onClick: () => {
        handleonClickCell(cell);
      },
      sx: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        "&:hover": {
          cursor: onClickEventCell ? "pointer" : null,
          border: onClickEventCell ? `1px solid #141414` : null,
        },
      },
    }),
    muiExpandAllButtonProps: ({ table }) => ({
      sx: {
        transform: table.getIsAllRowsExpanded()
          ? "rotate(180deg)"
          : "rotate(-90deg)",
        transition: "transform 0.2s",
      },
    }),
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
        transition: "transform 0.2s",
      },
    }),
    enableColumnResizing: true,
    enableFacetedValues: true,
    enablePagination: false,
    enableRowNumbers: rowNumbers,
    enableRowVirtualization: true,
    enableColumnFilterModes: true,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: "popover",
    isMultiSortEvent: () => multiSort,
    initialState,
    muiTableBodyRowProps: ({ row, table }) => ({
      onClick: () => (onClickEventRow ? onClick(row.original) : null),
      sx: {
        cursor: onClickEventRow ? "pointer" : null,
        backgroundColor:
          row.depth === 1
            ? "#a3a3a3"
            : table.getRowModel().rows.findIndex((r) => r.id === row.id) % 2 ===
              0
            ? "#fcfcfc"
            : "#E5E4E4",
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      },
    }),
    renderTopToolbarCustomActions: (table) => (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          p: "4px",
        }}
      >
        <Typography
          fontSize={titleFontSize}
          fontWeight="bold"
          sx={{ marginTop: "5px" }}
        >
          {title}
        </Typography>

        <Tooltip title="Clear Filters">
          <IconButton
            onClick={() => {
              handleClearAll(table);
            }}
          >
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
        {enableExport && (
          <Tooltip title="Export Data">
            <IconButton
              onClick={() => {
                handleonDownload(table.table.getRowModel().rows);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight, maxWidth },
    },

    muiTableHeadCellProps: {
      sx: {
        "& .Mui-TableHeadCell-Content": {
          justifyContent: "space-between",
          fontSize: HeadersFontSize,
        },
      },
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,

    renderBottomToolbarCustomActions: (table) => (
      <Typography>
        Total Rows: {table.table.getRowModel().rows.length}
      </Typography>
    ),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
    },
    rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 4 },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <DownloadFileModal
        fileName={title}
        open={openDownload}
        onClose={handleCloseDownload}
        Data={downloadData}
      />
    </>
  );
};

export default GenericTable;
