import React from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ListboxComponent from "./Listbox.tsx";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomDropdown = ({
  Title,
  Options,
  onChangeValue,
  loading = false,
}: any) => {
  const handleOnChange = (event: any, newValue: any) => {
    onChangeValue(newValue);
  };

  return (
    <Autocomplete
      multiple
      loading={loading}
      id="checkboxes-tags"
      options={Options}
      limitTags={2}
      disableListWrap
      disableCloseOnSelect
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      getOptionLabel={(option) => option}
      onChange={handleOnChange}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              sx={{
                color: "#2E7C67",
                "&.Mui-checked": {
                  color: selected ? "#2E7C67" : "#B2160E",
                },
              }}
            />
            {option}
          </li>
        );
      }}
      sx={{
        width: 300,
        margin: 2,
        "& .MuiInput-underline:before": {
          borderBottomColor: "#2E7C67", // Default underline color
        },
        "&:hover .MuiInput-underline:before": {
          borderBottomColor: "#2E7C67", // Underline color on hover
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#2E7C67", // Underline color when focused
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={Title}
          placeholder="Select Options"
          InputLabelProps={{
            sx: {
              color: "#2E7C67", // Label color
              "&.Mui-focused": {
                color: "#2E7C67",
              },
            },
          }}
        />
      )}
    />
  );
};

export default CustomDropdown;
