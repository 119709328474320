import React, { useState, useMemo } from "react";
import Sidebar from "../../components/layout/Sidebar/Sidebar.tsx";
import Header from "../../components/layout/Header/Header.tsx";
import Footer from "../../components/layout/Footer/Footer.tsx";
import AddNewSiteModal from "../../components/Modals/AddNewSiteModal.tsx";
import EditNewSiteModal from "../../components/Modals/EditNewSiteModal.tsx";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.tsx";
import { Button, Box } from "@mui/material";
import GenericComplexTable from "../../components/GenericComplexTable/GenericComplexTable.tsx";
import { usePortfolioTable } from "../../utils/api/usePortfolioTable.ts";
import { usePortfoliosDropdown } from "../../utils/api/usePortfoliosDropdown.ts";
import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal.tsx";
import useScreenSize from "../../utils/hooks/useScreenSize.ts";
import { useAuth } from "../../context/auth.context.tsx";
import AddNewPortfolioModal from "../../components/Modals/AddNewPortfolioModal.tsx";
import { portfolioTableColumns } from "./utils/TablesOptons.tsx";
import { usePortfolios } from "../../utils/api/usePortfolios.ts";

const Portfolios: React.FC = () => {
  const screenSize = useScreenSize();
  const { session, logOutAction } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddPortfolioModalOpen, setIsAddPortfolioModalOpen] = useState(false);
  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);
  const [siteId, setSiteId] = useState<any | null>(null);

  const {
    data: portfolioTableData,
    isLoading,
    isFetching,
    isError,
  } = usePortfolioTable(null);

  const {
    data: PortfoliosData,
    isLoading: isLoadingPortfoliosData,
    isError: isErrorPortfoliosData,
  } = usePortfolios(session);

  const filteredTableData = useMemo(() => {
    if (selectedPortfolios.length === 0 || !portfolioTableData) return [];

    return portfolioTableData.filter((row: any) => {
      const realPortfolioName = row.portfolio_name
        ? row.portfolio_name.replace(/_/g, " ")
        : ""; // Fallback if portfolio_name is undefined
      return selectedPortfolios.includes(realPortfolioName);
    });
  }, [selectedPortfolios, portfolioTableData]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenEditModal = (row: any) => {
    const idFromUrl = extractIdFromUrl(row.url);
    setSiteId(idFromUrl);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSiteId(null); // Clear the siteId on close
  };

  const handleDropdownChange = (newValue: string[]) => {
    setSelectedPortfolios(newValue);
  };

  const extractIdFromUrl = (url: string): any => {
    return url.match(/\/sites\/(\d+)\//)?.[1];
  };

  const handleDeleteRow = (row: any) => {
    const idFromUrl = extractIdFromUrl(row.url);
    setSiteId(idFromUrl);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSiteId(null);
  };

  const handleOpenAddPortfolioModal = () => {
    setIsAddPortfolioModalOpen(true);
  };

  const handleCloseAddPortfolioModal = () => {
    setIsAddPortfolioModalOpen(false);
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Sidebar />
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Header text="Portfolios" onLogout={logOutAction}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#2E7C67",
              color: "#FFFFFF",
            }}
            size="small"
            onClick={handleOpenAddPortfolioModal}
          >
            Add a New Portfolio
          </Button>
        </Header>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          m={2}
          p={2}
          bgcolor="#F5F5F5"
          borderRadius="4px"
          width="50%"
        >
          <CustomDropdown
            Title="Select Portfolio"
            Options={(PortfoliosData || []).map((portfolio) => portfolio.name)}
            onChangeValue={handleDropdownChange}
          />
          <Button
            variant="outlined"
            style={{
              color: "#2E7C67",
              borderColor: "#2E7C67",
              backgroundColor: "#FFFFFF",
            }}
            onClick={handleOpenModal}
          >
            Add a New Site
          </Button>
        </Box>

        <Box m={2}>
          <GenericComplexTable
            title="Portfolio Table"
            tableData={filteredTableData ? filteredTableData : []}
            isLoading={isLoading || isLoadingPortfoliosData}
            isFetching={isFetching}
            isError={isError || isErrorPortfoliosData}
            columns={portfolioTableColumns}
            maxHeight={"400px"}
            maxWidth={screenSize.width - 180}
            enableExport={true}
            enableBottomToolbar={true}
            onEditRow={handleOpenEditModal}
            onDeleteRow={handleDeleteRow}
          />
        </Box>

        <Footer />
      </div>
      <AddNewSiteModal open={isModalOpen} onClose={handleCloseModal} />
      {siteId && (
        <EditNewSiteModal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          id={siteId} // Ensure this `siteId` is being correctly updated for each row
        />
      )}
      {siteId && (
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          id={siteId}
        />
      )}

      <AddNewPortfolioModal
        open={isAddPortfolioModalOpen}
        onClose={handleCloseAddPortfolioModal}
      />
    </div>
  );
};

export default Portfolios;
