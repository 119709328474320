import React from 'react';
import { Tooltip } from '@mui/material';
import './Sidebar.css';
import Logo from '../../../assets/logo.svg';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="logo">
        <Tooltip title="Back to Hawkeye" arrow>
          <a href="https://hawkeye.luminace.com/" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Logo" />
          </a>
        </Tooltip>
      </div>
    </div>
  );
}

export default Sidebar;
